import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async unsubscribeFromNotifications() {
    const currentSubscription = await this._currentSubscription();
    if (currentSubscription != null) {
      currentSubscription.unsubscribe()
    }
  }

  async _currentSubscription() {
    const sw = await navigator.serviceWorker.ready;
    return sw.pushManager.getSubscription();
  }
}

